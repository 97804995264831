<template>
  <div class="content-center" id="discount-slabs-list-view">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="ProductsDiscount">
      <template v-slot:right-end>
        <div class="vx-row">


          <div class="w-full md:w-auto mx-2">
            <vs-select autocomplete v-model="selectedProductType" class="select-large"
                       :label="$t('ProductType')" @input="onProductTypeSelected">
              <vs-select-item :value="null" :text="$t('All')" />
              <vs-select-item :key="index.key" :value="item.key" :text="item.name"
                              v-for="(item,index) in productTypes" />
            </vs-select>
          </div>
          <div class="w-full md:w-auto" v-if="discountSlabs.length > 0">
            <vs-select
              :label="$t('SelectDiscountSlab')"
              v-model="selectedDiscountSlabId"
              @change="initDataFetchAgain"
              autocomplete
            >
              <vs-select-item :key="index"
                              :value="item.plan_id"
                              :text="item.plan_name"

                              v-for="(item,index ) in discountSlabs" />
            </vs-select>
          </div>
          <!--          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>-->
          <!--          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>-->
          <div class="p-3"></div>
          <div>
          <vs-button
            class="mt-6"
            @click="openAddDiscountSlab"
                     type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddDiscountSlab') }}
          </vs-button>
          </div>
        </div>
      </template>
    </breadcrumb-base>

    <!--    Empty List State -->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="discountSlabs.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--               class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
        </div>
      </div>
    </transition>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>
    <div class="vx-row w-full mb-base c-center flex items-center justify-around mx-6">
      <div class="w-full">
        <div class="flex pr-8  w-full">
          <vs-spacer></vs-spacer>
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>
          <div class="p-3"></div>
          <div class="" style="
                text-align: center;
                vertical-align: middle;
                display: flex;
                justify-content: flex-end;
            ">
            <div style="margin: auto 0;">
              <vs-button color="success"  @click="updateProductsDiscount">
                {{ $t('UpdateDiscounts') }}
              </vs-button>
            </div>
          </div>
        </div>
        <div class="py-4">

          <div class="flex flex-wrap">
            <div class="vs-row w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4 pr-2" v-for="config in unAssignedData">
              <div class="mb-4 mt-4 mr-2 product-discount-card">

                <div class="vx-row vs-align-center text-center" style="place-items: center;">
                  <div class="vs-col item-card-text-holder">
                    <h1 class="mx-3 item-card-text" style="font-size: 14px;">
                      {{config.product_name | truncate(16)}}
                      <span style="font-weight: 500 !important;">
                         - {{config.value | germanNumberFormat}}
                      </span>
                    </h1>
                    <p class="mx-3">
                      <span v-if="activeUserInfo.account_role == 1" class=""
                            style="font-weight: 600;font-size: 12px;"> {{ $t('BuyingDiscount')}} - {{ config.reseller_discount }} %</span>
                    </p>
<!--                    <p class="mx-3 item-card-subtitle" style="font-weight: 500;">-->
<!--                      {{config.value | germanNumberFormat}}-->

<!--                    </p>-->
                  </div>
                  <vs-spacer></vs-spacer>
<!--                  <vs-button radius @click="decrementConfigDiscount(config.configuration)" class="mx-2" size="normal" color="danger" type="filled"-->
<!--                             icon-pack="feather" icon="icon-minus"></vs-button>-->
                  <div class="flix-input-holder mx-4">
                    <div class="">
                      <input v-model="updatedConfigs[config.configuration]"
                             type="text"
                             placeholder="..." class="flix-input-field"
                             @change="changedDiscount($event, config.configuration)">
                    </div>
                  </div>
<!--                  <vs-button radius @click="incrementConfigDiscount(config.configuration)" class="mx-2" size="normal" color="primary" type="filled"-->
<!--                             icon-pack="feather" icon="icon-plus"></vs-button>-->
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="vs-row w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4 pr-2" v-for="config in assignedData">
              <div class="mb-4 mt-4 mr-2 product-discount-card">
                <div class="vx-row vs-align-center text-center" style="place-items: center;">
                  <div class="vs-col item-card-text-holder">
<!--                    <h1 class="mx-3 item-card-text">{{config.product_name | truncate(16)}}</h1>-->
<!--                    <p class="mx-3 item-card-subtitle">{{config.configuration_value | germanNumberFormat}}</p>-->
                    <h1 class="mx-3 item-card-text" style="font-size: 14px;">
                      {{config.product_name | truncate(16)}}
                      <span style="font-weight: 500 !important;">
                         - {{config.configuration_value | germanNumberFormat}}
                      </span>
                    </h1>
                    <p class="mx-3">
                      <span v-if="activeUserInfo.account_role == 1" class=""
                            style="font-weight: 600;font-size: 12px;"> {{ $t('BuyingDiscount')}} - {{ config.reseller_discount }} %</span>
                    </p>
                  </div>
                  <vs-spacer></vs-spacer>
<!--                  <vs-button radius @click="decrementConfigDiscount(config.configuration_id)" class="mx-2" size="normal" color="danger" type="filled"-->
<!--                             icon-pack="feather" icon="icon-minus"></vs-button>-->
                  <div class="flix-input-holder mx-4">
                    <div class="">
                      <input v-model="updatedConfigs[config.configuration_id]"
                             type="text"
                             placeholder="..." class="flix-input-field"
                             @change="changedDiscount($event, config.configuration_id)">
                    </div>
                  </div>
<!--                  <vs-button radius @click="incrementConfigDiscount(config.configuration_id)" class="mx-2" size="normal" color="primary" type="filled"-->
<!--                             icon-pack="feather" icon="icon-plus"></vs-button>-->
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewDiscountSlabs',
  computed: {
    discountSlabs() {
      return this.$store.state.pad.discountSlabs
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    unAssignedData() {
      return this.unAssignedProductsData.filter((p) => {
        if (this.selectedProductType == null) return true
        return p.product_type === this.selectedProductType;
      })
    },
    assignedData() {
      return this.assignedProductsData.filter((p) => {
        if (this.selectedProductType == null) return true
        return p.product_type === this.selectedProductType;
      })
    }
  },
  data() {
    return {
      isMounted: false,
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'DiscountSlabs', i18n: 'DiscountSlabs', url: '/discount-slabs' },
        { title: 'ProductsDiscount', i18n: 'ProductsDiscount', active: true },
      ],
      selectedDiscountSlab: null,
      selectedDiscountSlabId: null,
      assignedProductsData: [],
      dummyAssignedProductsData: [],
      unAssignedProductsData: [],
      dummyUnAssignedProductsData: [],
      updatedConfigs: {},
      initialUpdatedConfigs: {},
      unAssignedGlobalDiscount: 0,
      isDirty: false,
      selectedAlpha: '',
      selectedAlphaCurrentX: '',
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',

      selectedProductType: null,
      productTypes: [
        {
          'name': 'Cash Cards',
          'key': 'cash card',
        },
        {
          'name': 'Gift Cards',
          'key': 'gift card',
        },
        {
          'name': 'Calling Cards',
          'key': 'calling card',
        },
      ],
    }
  },
  props: {
    discountSlabId: {
      type: Number | String,
    },
  },
  methods: {

    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1
      this.assignedProductsData = this.dummyAssignedProductsData
      this.unAssignedProductsData = this.dummyUnAssignedProductsData
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.assignedProductsData = this.dummyAssignedProductsData.filter((p) => (p.product_name == null ? '' : p.product_name).startsWith(this.selectedAlpha))
        this.unAssignedProductsData = this.dummyUnAssignedProductsData.filter((p) => (p.product_name == null ? '' : p.product_name).startsWith(this.selectedAlpha))
      }
    },
    openAddDiscountSlab() {
      this.$router.push({ name: 'discount-slabs-add' })
    },
    decrementConfigDiscount(id) {
      this.isDirty = true
      if (this.updatedConfigs[id] > 0) {
        const obj = this.updatedConfigs
        this.updatedConfigs = {}
        const v = Number(obj[id])
        obj[id] = v - 1
        this.updatedConfigs = obj
      }
    },
    changedDiscount(val, id) {
      this.isDirty = true
      const obj = this.updatedConfigs
      this.updatedConfigs = {}
      obj[id] = val.target.value
      this.updatedConfigs = obj
    },
    incrementConfigDiscount(id) {
      this.isDirty = true
      const obj = this.updatedConfigs
      this.updatedConfigs = {}
      const v = Number(obj[id]) || 0
      obj[id] = v + 1
      this.updatedConfigs = obj
    },
    updateGlobalUnAssigned() {
      this.isDirty = true
      const obj = this.updatedConfigs
      this.updatedConfigs = {}

      this.unAssignedProductsData.forEach((c) => {
        obj[c.configuration] = Number(this.unAssignedGlobalDiscount)
      })
      this.updatedConfigs = obj
    },
    initDataFetchAgain() {
      this.updatedConfigs = {}
      this.unAssignedGlobalDiscount = 0
      this.fetchDiscountSlabsProducts()
    },
    fetchDiscountSlabs() {
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabs')
        .then((data) => {
        })
        .catch((error) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: $t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchDiscountSlabsProducts() {
      const payload = {
        'discount_slab_id': this.selectedDiscountSlabId,
      }
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabProducts', payload)
        .then((data) => {
          this.$vs.loading.close()
          // productsData
          if (data.message_type !== 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            this.assignedProductsData = data.product_configuration_slabs
            this.dummyAssignedProductsData = data.product_configuration_slabs
            this.unAssignedProductsData = data.unassigned_product_configurations
            this.dummyUnAssignedProductsData = data.unassigned_product_configurations

            const obj = {}
            this.assignedProductsData.forEach((c) => {
              obj[c.configuration_id] = c.discount
            })
            this.updatedConfigs = obj
            this.initialUpdatedConfigs = { ...obj }
            this.isDirty = false
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: $t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    updateProductsDiscount() {
      const p = []
      let hasError = false
      Object.keys(this.updatedConfigs).forEach((k) => {
        const n = Number(this.updatedConfigs[k]) || 0
        const eN = Number(this.initialUpdatedConfigs[k]) || -1
        const isExisting = eN !== -1

        if (n > 100) {
          hasError = true
        }

        if (eN !== n) {
          if (n > 0) {
            p.push({ 'configuration_id': k, 'discount': n })
          }
          if (n === 0 && isExisting) {
            p.push({ 'configuration_id': k, 'discount': n })
          }
        }
      })
      if (hasError) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Invalid Discount',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const payload = {
        'discount_slab_id': this.selectedDiscountSlabId,
        'update_list': JSON.stringify(p),
      }
      this.$vs.loading()
      return this.$store.dispatch('pad/updateDiscountSlabProducts', payload)
        .then((data) => {
          this.$vs.loading.close()
          // productsData
          if (data.message_type !== 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            this.assignedProductsData = data.product_configuration_slabs
            this.unAssignedProductsData = data.unassigned_product_configurations

            const obj = {}
            this.assignedProductsData.forEach((c) => {
              obj[c.configuration_id] = c.discount
            })
            this.updatedConfigs = obj
            this.initialUpdatedConfigs = { ...obj }
            this.unAssignedGlobalDiscount = 0
            this.isDirty = false
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: $t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.selectedDiscountSlabId = this.discountSlabId
    this.fetchDiscountSlabs()
    this.fetchDiscountSlabsProducts()
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        acceptText: 'Leave',
        text: 'You have unsaved changes. will be lost',
        accept: next,
      })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss">
#discount-slabs-list-view {
  .vs-slider {
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background: #f0f0f0;
    position: relative;
    cursor: pointer;
    border: 0;
    margin: 0;
    display: block;
    overflow: hidden;
  }
  .vs-slider--circle {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -6px;
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    margin: 0;
    z-index: 200;
  }
  .vs-slider--circle-text {
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 1.75rem;
  }
  input.mx-input {
    padding: 18px !important;
  }
  .flix-input-holder {
    width: 50px;
    padding: 0;
    margin: 0;
  }
  .flix-input-field {
    height: 44px;
    border: 1px solid #c8c8c8;
    text-align: center;
    width: 100%;
    text-indent: 0;
    border-radius: 5px;
    font-size: 24px;
  }

  .item-card-text-holder {
    text-align: start;
  }
  .item-card-text {
    font-size: 20px;
    font-weight: bolder;
  }
  .item-card-subtitle {
    font-size: 16px;
  }
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  .product-discount-card {
    padding: 5px 8px;
    background: white;
    box-shadow: 0px 0px 1px 0px black;
  }
}
</style>
